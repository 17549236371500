import React from 'react'
import classNames from 'classnames'
import Layout from 'layouts/zh'
import {
  Seo,
  Banner,
  SummaryTitle,
  TabSwitcher,
  MoreResources,
  Button,
  ScanCodeConsultButton,
  TestimonyCard,
  PageBottomCard,
} from 'components/index'
import CardGroup from 'components/CdpCom/CardGroup'

import * as styles from './index.module.less'
import CardsCol from 'components/CdpCom/CardsCol'
import Carousel from 'components/Carousel'
import classnames from 'classnames'
import AnalysisCard from 'components/Analysis/AnalysisCard'

import part1_1 from 'assets/images/product/cdp/part1_1.png'
import part1_2 from 'assets/images/product/cdp/part1_2.png'
import part1_3 from 'assets/images/product/cdp/part1_3.png'
import part1_4 from 'assets/images/product/cdp/part1_4.png'
import part1_5 from 'assets/images/product/cdp/part1_5.png'
import part2_1 from 'assets/images/product/cdp/part2_1.png'
import part2_2 from 'assets/images/product/cdp/part2_2.png'
import part2_3 from 'assets/images/product/cdp/part2_3.png'
import part2_4 from 'assets/images/product/cdp/part2_4.png'
import part2_5 from 'assets/images/product/cdp/part2_5.png'
import part3_icon1 from 'assets/images/product/cdp/part3_icon1.svg'
import part3_icon2 from 'assets/images/product/cdp/part3_icon2.svg'
import part3_icon3 from 'assets/images/product/cdp/part3_icon3.svg'
import part3_icon4 from 'assets/images/product/cdp/part3_icon4.svg'
import part4_img from 'assets/images/product/cdp/part4_img.png'
import part4_icons from 'assets/images/product/cdp/part4_icons.png'
import part5_1 from 'assets/images/product/cdp/part5_1.png'
import part5_2 from 'assets/images/product/cdp/part5_2.png'
import part5_3 from 'assets/images/product/cdp/part5_3.png'
import part5_4 from 'assets/images/product/cdp/part5_4.png'
import part6_1 from 'assets/images/product/cdp/part6_1.png'
import part6_2 from 'assets/images/product/cdp/part6_2.png'
import part6_3 from 'assets/images/product/cdp/part6_3.png'
import part6_4 from 'assets/images/product/cdp/part6_4.png'

interface cdpProps {
  [x: string]: any
}

const defaultButtons = [
  { text: '预约演示', btnType: 'primary', href: 'https://www.sensorsdata.cn/form/parade.html', target: '_blank' },
]

const part2Data = [
  {
    title: '数据集成',
    sortTitle: '数据集成',
    content: {
      img: part2_1,
      introduction1: '全域数据的采集和接入',
      introductionList: [
        { content: '· 轻松接入全域数据源，包括用户行为数据、业务数据、第三方数据、数据仓库/数据湖等' },
        { content: '· 可视化数据接入框架，如 50+ SDK、 批量导入工具、数仓表映射等' },
      ],
      buttons: defaultButtons,
    },
  },
  {
    title: '数据建模',
    sortTitle: '数据建模',
    reverse: true,
    content: {
      img: part2_2,
      introduction1: '便捷易用的数据模型',
      introductionList: [
        { content: '· 实时一对多的 ID-Mapping，支持全域数据关联，构建全域统一的用户体系' },
        { content: '· 支持多实体模型，将用户、员工、门店、产品等定义为实体，支撑复杂营销场景' },
        { content: '· 支持业务明细数据，如订单数据、持仓明细、线下核销数据等，实现用户行为数据与业务经营数据的融合' },
      ],
      buttons: defaultButtons,
    },
  },
  {
    title: '数据加工',
    sortTitle: '数据加工',
    reverse: true,
    content: {
      img: part2_3,
      introduction1: '灵活且易用的圈选能力',
      introductionList: [
        { content: '· 面向业务的实体查询语言 EQL，让复杂逻辑圈选更简单，实现灵活便捷快速的查询' },
        { content: '· 灵活的规则引擎，支持各种复杂规则定义，例如多层级、交并差的规则配置等' },
        { content: '· 易用的产品交互，满足不同类型用户的使用需求，让业务人员能够自主上手' },
      ],
      buttons: defaultButtons,
    },
  },
  {
    title: '数据应用',
    sortTitle: '数据应用',
    reverse: true,
    content: {
      img: part2_4,
      introduction1: '全域标签体系和画像洞察',
      introductionList: [
        { content: '· 通过可视化界面交互方式，结合业务场景需求， 自助创建和维护用户标签' },
        { content: '· 构建客户360度画像，形成全面深度洞察，从而指导下一步经营动作' },
      ],
      buttons: defaultButtons,
    },
  },
  {
    title: '平台能力',
    sortTitle: '平台能力',
    reverse: true,
    content: {
      img: part2_5,
      introduction1: '开放平台能力',
      introductionList: [
        { content: '· 实时数据处理能力，支持秒级端到端的数据流，支持实时数据订阅输出' },
        { content: '· 支持流式和批量输出，支持高并发实时查询，支持万级 QPS，轻松支撑各类在线营销业务' },
        { content: '· 作为数据底座，无缝对接神策全系列软件产品，Open API 灵活进行能力扩展' },
      ],
      buttons: defaultButtons,
    },
  },
].map((item) => ({
  ...item,
  reverse: true,
  showIcon: false,
}))

const part3Data = [
  { icon: part3_icon1, title: '全景画像洞察', contentText: '构建 360 度全景画像，还原用户真实面貌，洞察特征偏好' },
  { icon: part3_icon2, title: '数据资产沉淀', contentText: '整合多源数据，汇聚全局视图，沉淀企业数据资产' },
  {
    icon: part3_icon3,
    title: '赋能精细化运营',
    contentText: '支持灵活丰富的用户分层能力，针对不同群体定制个性化的运营策略',
  },
  {
    icon: part3_icon4,
    title: '支撑复杂营销场景',
    contentText: '基于客户、员工、产品等不同实体的特征提取，实现「产品特征-客户偏好匹配」、智慧导购等复杂营销策略',
  },
]

const part4Data = {
  imgUrl: part4_img,
  title: 'CDP 实现五大指标全面提升',
  text: [
    '妍丽信息中心团队与神策数据展开 CDP 项目合作，解决了数据孤岛、CDP 能力缺失、计算能力制约用户触达、数据回流困难等诸多数据问题，实现了新会员 GMV、新会员数量、新会员 ARPU 值、注册用户数及首单转化率的全面提升。',
    '“神策 CDP 项目上线对于妍丽是一个里程碑的事件，也对妍丽团队提出了更高的要求，我们需要学会如何用这些数据，并用好这些数据转化为生产力。”',
  ],
  signature: '—— 妍丽 CEO',
}

const part5Data = [
  {
    title: '实体查询语言 EQL',
    desc: '面向业务人员的实体查询语言，支持多实体对象查询，提供更简单、高效的数据加工方式',
    img_url: part5_1,
  },
  {
    title: '数据资产视图',
    desc: '全局管理企业数据资产，提供从总体到细节的数据洞察能力',
    img_url: part5_2,
  },
  {
    title: '标签/分群管理',
    desc: '丰富的数据加工方式，灵活适配多种业务场景，并支持结构化、可视化、标准化的标签/分群管理',
    img_url: part5_3,
  },
  {
    title: '数据服务',
    desc: '提供丰富的 Open API 、插件化、以及数据输出能力，灵活支持与外部系统集成',
    img_url: part5_4,
  },
]

const part6Data = [
  {
    img: part6_1,
    title: '神策数据入选 IDC 中国客户数据平台 CDP …',
    tip: '文章',
    url: 'https://www.sensorsdata.cn/blog/20230414',
    text: '立即阅读',
  },
  {
    img: part6_2,
    title: '新一代的营销数据平台',
    tip: '文章',
    url: 'https://www.sensorsdata.cn/blog/20221122',
    text: '立即阅读',
  },
  {
    img: part6_3,
    title: '《CDP 全域用户关联数据体系建设...',
    tip: '资料',
    url: 'https://www.sensorsdata.cn/school/library/2789b755-1cfd-4eac-aaf4-1a6ebbf3455e',
    text: '免费下载',
  },
  {
    img: part6_4,
    title: '《企业数据治理价值解读与场景实践》',
    tip: '资料',
    url: 'https://www.sensorsdata.cn/school/library/6e2c65db24a3f6cf408e969329c69210',
    text: '免费下载',
  },
]

const Buttons = () => (
  <div className={styles.buttons}>
    <Button btnType="primary" useLink target="_blank" href="https://www.sensorsdata.cn/form/parade.html">
      预约演示
    </Button>
    <ScanCodeConsultButton
      qrcode="https://ow-file.sensorsdata.cn/www/product/analysis/wechatQRcode.png"
      buttonText="立即咨询"
      imgBottomText="微信扫码 立即咨询"
      btnType="primary"
      ghost
    />
  </div>
)

const Cdp: React.FC<cdpProps> = (props) => {
  return (
    <Layout {...props}>
      <Seo
        title="神策数界平台 | 企业级客户数据平台"
        description="为助力企业实现全域客户经营，神策数界平台通过整合多源数据、关联全域 ID、扩展数据模型、构建客户画像标签等强大的数据治理能力，帮助企业构建客户数据平台，为业务分析洞察、自动化营销等场景提供数据支持。"
        keywords="神策数界平台,客户经营,CDP,客户数据平台,数据分析,数据治理"
      />
      <div className={styles.cdpRoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title="神策数界平台"
            subTitle="为全域客户经营提供数据基石"
            desc="企业级客户数据平台（CDP），解决数据孤岛问题，建立统一的用户、客户、产品档案，通过数据驱动客户经营，帮助企业实现数字化转型"
            h1tag={true}
            className={classNames(styles.banner)}
            bannerClassname={styles.bannerContent}
            bannerTitleClassName={styles.bannerTitle}
            bannerSubTitleClassName={styles.bannerSubTitle}
            bannerBtnsClass={styles.bannerBtns}
            bannerDescClassName={styles.bannerDesc}
            buttons={[
              { text: '预约演示', href: 'https://www.sensorsdata.cn/form/parade.html', btnType: 'primary' },
              {
                useQrCode: true,
                qrcode: 'https://ow-file.sensorsdata.cn/www/product/analysis/wechatQRcode.png',
                buttonText: '立即咨询',
                imgBottomText: '微信扫码 立即咨询',
                btnType: 'primary',
                ghost: true,
              },
            ]}
          />
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="五大核心优势，打造企业级 CDP" />
          <div className={styles.partContent}>
            <CardGroup
              data={[
                {
                  title: '全域数据接入和打通',
                  imageSrc: part1_1,
                  contentLines: ['· 全域数据关联的 ID-Mapping 方案', '· 支持多种数据接入方式的工具集'],
                },
                {
                  title: '实时数据处理能力',
                  imageSrc: part1_2,
                  contentLines: ['· 支持秒级端到端的数据流', '· 支持实时数据订阅输出'],
                },
                {
                  title: '灵活且易用的圈选',
                  imageSrc: part1_3,
                  contentLines: ['· 灵活的规则引擎，支持复杂规则定义', '· 易用的产品交互，满足不同用户使用'],
                },
                {
                  title: '低实施成本',
                  imageSrc: part1_4,
                  contentLines: [
                    '· 可直接映射数仓表，保留业务语义，无需投入大量资源进行 ETL 方案设计和实施',
                    '· 可视化的数据接入、数据建模、数据加工界面，不依赖开发人员，对业务友好',
                  ],
                },
                {
                  title: '高性能查询',
                  imageSrc: part1_5,
                  contentLines: [
                    '· 每日可处理上千个策略的上万个客群的圈选计算，日产出计算结果超过 200 亿人次',
                    '· 提供高并发 (三节点上万并发) 低延迟 (毫秒级) 的单体标签画像查询能力',
                  ],
                },
              ]}
            />
          </div>
          <Buttons />
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="全域、实时、灵活的客户数据平台" />
          <div className="lg:w-[1200px] mt-[16px] mx-auto lg:mt-[60px] lg:mb-[80px]">
            <TabSwitcher data={part2Data} longTitle align="space-between" mbShowShadow={false} autoWAndH></TabSwitcher>
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="整合全域数据，赋能企业数字化运营" />
          <div className={styles.partContent}>
            <CardsCol data={part3Data} />
          </div>
          <Buttons />
        </div>
        <div className={styles.bWarrper}>
          <div className="flex justify-center md:mt-[0px] pl-[20px] pr-[20px]">
            <TestimonyCard {...part4Data} />
          </div>
          <div
            className={classnames(
              'md:h-[100px] h-[50px] lg:w-[1070px] lg:px-0 mx-[auto] px-[20px] w-full',
              styles.carouselWrapper,
            )}
          >
            <Carousel img={part4_icons} className={styles.carousel} showMask />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="更多功能，夯实您的数据根基" />
          <div className="lg:w-[1200px] lg:mt-[60px] mt-[26px] mx-auto px-[2rem] lg:px-0 lg:flex lg:justify-between">
            {part5Data.map((item) => {
              return <AnalysisCard className="mt-[20px] lg:mt-0" {...item} key={item?.title} />
            })}
          </div>
          <Buttons />
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="更多干货" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <MoreResources data={part6Data} />
          </div>
        </div>
        <div className="h-[100px] hidden md:block"></div>
        <div className="pt-[6rem] md:pt-[0px] bg-[#f9fafc]">
          <PageBottomCard
            title="开启大数据分析与营销科技之旅！"
            desc="立即注册，和神策数据专家一起探讨数字化！"
            leftButtonText="体验 Demo"
            leftButtonHref="/doPharmMed"
            rightButtonText="预约演示"
            rightButtonHref="/form/parade.html"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Cdp
